import PageContainer from '../../components/PageContainer'
import styled from 'styled-components'

export const UsersContainer = styled(PageContainer)`
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    > h1 {
      margin: 0;
    }
  }
`
