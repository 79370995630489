import { jsPDF } from 'jspdf'
import moment from 'moment'
import 'jspdf-autotable'
import { ISSUES_REF, FIREBASE_TIMESTAMP } from '.'
import firebase from '../'
import { IssuesTypesWithId, IssuesTypes, IssuesHistory } from '../../../types'

interface CreateIssue {
  title: string
  description: string
  home: string
  room: string
  reportedBy?: string | null
  updatedBy: null
}

interface UpdateStatus {
  id: string
  comments: string
  progress: string
  updatedBy: string | null
}

export const updateIssue = async (data: IssuesTypesWithId) => {
  const { id, ...rest } = data
  await ISSUES_REF.doc(id).update({ ...rest })
}

export const updateProgress = async ({ id, comments, progress, updatedBy }: UpdateStatus) => {
  await ISSUES_REF.doc(id).update({
    comments,
    progress,
    updatedBy,
  })
}

export const createIssue = async (data: CreateIssue) => {
  await ISSUES_REF.add({ reportedAt: FIREBASE_TIMESTAMP, progress: 'To Do', ...data })
}

export const deleteIssues = async (ids: string[]) => {
  const batch = firebase.firestore().batch()

  ids.map((id) => batch.delete(ISSUES_REF.doc(id)))

  await batch.commit()
}

const generatePDF = (data: any) => {
  const doc = new jsPDF()
  //@ts-ignore
  doc.autoTable({ html: '#my-table' })
  //@ts-ignore
  doc.autoTable({
    theme: 'grid',
    columnStyles: { 6: { minCellWidth: 50 } },
    head: [['Issue', 'Reported by', 'Home', 'Time ago', 'Room', 'Progress', 'Comments']],
    body: data,
  })

  doc.save('table.pdf')
}

const formatPdfData = async (data: IssuesTypesWithId[]) => {
  return await Promise.all(
    data.map(async (x) => {
      const { reportedAt, description, home, progress, reportedBy, room, title } = x
      const formattedReportAt = moment.unix(reportedAt.seconds).fromNow()
      const ref = ISSUES_REF.doc(x.id).collection('history')
      const formattedComments: any = []
      const history = await ref.get()

      history.forEach((y) => {
        const { updatedAt, ...other } = y.data() as IssuesHistory
        if (other.comments) {
          const parseUpdatedAt = moment.unix(updatedAt.seconds).format('DD/MM/YYYY HH:mm')
          formattedComments.push(`${parseUpdatedAt}:\n${other.comments}`)
        }
      })

      return [
        `${title}\n${description}`,
        reportedBy,
        home,
        formattedReportAt,
        room,
        progress,
        formattedComments.toString().replace(/,/g, '\n\n'),
      ]
    })
  )
}

export const downloadPDF = async (ids: string[]) => {
  const ref = ISSUES_REF.where(firebase.firestore.FieldPath.documentId(), 'in', ids)
  const result = await ref.get()
  const formattedData: any = []

  result.forEach(async (x) => {
    if (x.exists) {
      formattedData.push({
        id: x.id,
        ...(x.data() as IssuesTypes),
      })
    }
  })

  const results = await formatPdfData(formattedData)

  if (results.length > 0) {
    console.log('downloadPDF -> formattedData', results)
    generatePDF(results)
  }
}
