import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { UserRoles } from '../../../services/types'
import NotFound from '../../../scenes/NotFound'

interface ProtectedRouteProps extends RouteProps {
  requiredRole: UserRoles[]
  user: any
}

const ProtectedRoute = ({ user, requiredRole, path, component, ...otherProps }: ProtectedRouteProps) => {
  if (requiredRole.some((x) => x === user?.role)) {
    return <Route path={path} component={component} {...otherProps} />
  }

  return <Route component={NotFound} path={path} />
}

export default ProtectedRoute
