import { useState } from 'react'
import { ToastMessageProps } from '../../components/Toast'

const useToast = () => {
  const [toast, setToast] = useState<ToastMessageProps>({ value: '' })

  return { toast, setToast }
}

export default useToast
