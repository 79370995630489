import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Modal, Select, Button } from '@gearedapp/ga-storybook'
import ButtonLink from '../../components/ButtonLink'
import { HistoryDataListProps, IssuesTypes, IssuesTypesWithId } from '../../services/types'
import Toast from '../../components/Toast'
import useToast from '../../services/hooks/useToast'
import { ISSUES_REF } from '../../services/api/firebase/firestore'
import Spinner from '../../components/Spinner'
import history from '../../services/config/router'
import { IssuesContainer } from './styles'
import useAuth from '../../services/hooks/useAuth'
import { deleteIssues, downloadPDF } from '../../services/api/firebase/firestore/issues'

const progressOptions = [
  {
    value: 'Show All',
    label: 'Show All',
  },
  {
    value: 'To Do',
    label: 'To Do',
  },
  {
    value: 'In Progress',
    label: 'In Progress',
  },
  {
    value: 'Done',
    label: 'Done',
  },
]

const homeOptions = [
  {
    value: 'Show All',
    label: 'Show All',
  },
  {
    value: 'Thorburn Manor',
    label: 'Thorburn Manor',
  },
  {
    value: 'Morningside Manor',
    label: 'Morningside Manor',
  },
  {
    value: 'Lorimer House',
    label: 'Lorimer House',
  },
  {
    value: 'Canal View Care',
    label: 'Canal View Care',
  },
  {
    value: 'Hapland Bow',
    label: 'Hapland Bow'
  },
  {
    value: 'Balcarres Court',
    label: 'Balcarres Court'
  },
  {
    value: '33/5 North Meggetland',
    label: '33/5 North Meggetland'
  },
  {
    value: 'The Gallolee',
    label: 'The Gallolee'
  },
  {
    value: '46/6 North Meggetland',
    label: '46/6 North Meggetland'
  },
]

const Issues = ({ location: { state }, history: { action } }: HistoryDataListProps) => {
  const { user, loading } = useAuth()
  const [modalToggle, setModalToggle] = useState<string[]>([])
  const { toast, setToast } = useToast()
  const [data, setData] = useState<IssuesTypesWithId[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [progressFilter, setProgressFilter] = useState(progressOptions[0])
  const [homeFilter, setHomeFilter] = useState(homeOptions[0])

  useEffect(() => {
    if (state && state.toast && action === 'PUSH') setToast(state.toast)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isMounted = true
    const unSubscribe = () => {
      const whereProgress = progressFilter.value !== 'Show All'
      const whereHome = homeFilter.value !== 'Show All'
      let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ISSUES_REF

      if (whereProgress) {
        query = query.where('progress', '==', progressFilter.value)
      }
      if (whereHome) {
        query = query.where('home', '==', homeFilter.value)
      }

      query.orderBy('reportedAt', 'desc').onSnapshot((snapShot) => {
        const issues = snapShot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as IssuesTypes),
        }))

        setData(issues)
        setIsLoading(false)
      })
    }

    if (isMounted) unSubscribe()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressFilter, homeFilter])

  if (isLoading || loading) {
    return <Spinner />
  }

  const editRow = (uid: string) => {
    history.push({
      pathname: `issues/${uid}`,
      state: data.find((x) => x.id === uid),
    })
  }
  const viewRow = (uid: string) => {
    history.push(`view-issue/${uid}`)
  }
  const deleteRow = (uid: string[]) => {
    setModalToggle(uid)
  }

  const handleUtilityOptions = () => {
    switch (user?.role) {
      case 'ADMIN':
      case 'MANAGER':
        return { editRow, viewRow, deleteRow }
      case 'MAINTENANCE':
        return { editRow, viewRow }

      default:
        return viewRow
    }
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <Modal
        toggle={modalToggle.length > 0}
        setToggle={(x) => {
          if (!x) {
            setModalToggle([])
          }
        }}
        title="Remove Issues"
        actionTitle="Remove"
        action={async () => {
          if (modalToggle.length > 0) {
            await deleteIssues(modalToggle)
            setModalToggle([])
          }
        }}
      >
        <p>
          {modalToggle.length > 1
            ? `Are you sure you want to remove this ${modalToggle.length} users from the app?`
            : `Are you sure you want to remove ${
                data.find((x) => x.id === modalToggle[0])?.title
              } from the app?`}
        </p>
      </Modal>
      <IssuesContainer>
        <div>
          <h1>Issues</h1>
          <ButtonLink to="add-issue">Report Issue</ButtonLink>
        </div>

        <div>
          <Select
            label="Home"
            id="home filter"
            options={homeOptions}
            setValue={setHomeFilter}
            value={homeFilter}
            full
          />
          <Select
            label="Progress"
            id="progress filter"
            options={progressOptions}
            setValue={setProgressFilter}
            value={progressFilter}
            full
          />
        </div>

        {data.length > 0 ? (
          <Table
            headCustomUtilities={[
              (ids) => (
                <Button
                  style={{ marginRight: 18 }}
                  small
                  disabled={ids.length === 0}
                  onClick={async () => {
                    try {
                      await downloadPDF(ids)
                    } catch (error) {
                      console.log('Issues -> error', error)
                    }
                  }}
                >
                  Download selected
                </Button>
              ),
            ]}
            data={data}
            uid="id"
            fields={[
              {
                field: 'title',
                header: 'Title',
                enableSorting: true,
              },
              {
                field: 'home',
                header: 'Home',
                enableSorting: true,
              },
              {
                field: 'reportedAt',
                header: 'Created',
                render: (value) => {
                  return <p style={{ margin: 0 }}>{moment.unix(value.seconds).fromNow()}</p>
                },
                enableSorting: true,
              },
              {
                field: 'reportedBy',
                header: 'Report By',
                enableSorting: true,
              },
              {
                field: 'progress',
                header: 'Progress',
                enableSorting: true,
              },
            ]}
            pagination
            pagesToDisplay={[
              {
                value: 5,
                label: '5',
              },
              {
                value: 10,
                label: '10',
              },
              {
                value: 30,
                label: '30',
              },
              {
                value: 50,
                label: '50',
              },
            ]}
            {...handleUtilityOptions()}
          />
        ) : (
          <h4>There are no Issues.</h4>
        )}
      </IssuesContainer>
    </>
  )
}

export default Issues
