import styled, { keyframes } from 'styled-components'
import { animated } from 'react-spring'

type ToastContainerProps = {
  type: 'success' | 'error' | 'info'
}

const selectMessageBackground = (type: 'success' | 'error' | 'info') => {
  switch (type) {
    case 'error':
      return 'var(--danger)'
    case 'info':
      return 'var(--info)'

    default:
      return 'var(--primary)'
  }
}

export const ToastContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  position: fixed;
  top: 12px;
  right: 12px;
  width: 100%;
  background-color: ${({ type }: ToastContainerProps) => selectMessageBackground(type)};
  border-bottom: 1px solid ${({ type }: ToastContainerProps) => selectMessageBackground(type)};
  padding: 20px;
  z-index: var(--modal);
  width: 300px;
`
export const Message = styled.div`
  color: white;
  margin-left: 12px;
`

const progressBarAnimation = keyframes`
  0% {
      width: 100%;
    }
  100% {
    width: 0%;
  }
`

export const Timer = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: white;
  animation: ${progressBarAnimation} 7s ease;
`
