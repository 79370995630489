import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonCSS } from '@gearedapp/ga-storybook/'

export default styled(
  ({ secondary, ghost, simple, danger, full, disabled, iconLeft, iconRight, small, ...props }) => (
    <Link {...props} />
  )
)`
  ${ButtonCSS}
  display: inline;
  font-weight: bold;
`
