import styled from 'styled-components'
import PageContainer from '../../../../components/PageContainer'
import { BREAK_POINTS } from '../../../../services/styles/constants'

export const ViewIssueContainer = styled(PageContainer)`
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 18px;
    > div:first-of-type {
      background-color: var(--white);
      border-radius: 3px;
      padding: 16px;
      box-shadow: 1px 2px 9px 1px rgba(0, 0, 0, 0.05);
      align-self: flex-start;
      textarea {
        width: 100%;
        min-height: 200px;
      }
      > div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          max-width: 130px;
        }
        > h2 {
          margin: 0;
        }
      }

      > div:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > p:first-of-type {
          color: var(--text-disabled);
          margin-bottom: initial;
        }
      }

      > p:last-of-type {
        font-size: 1rem;
        margin-bottom: 30px;
        font-size: 0.833rem;
      }
      > p:first-of-type {
        margin-bottom: 0;
        color: var(--text-disabled);
      }

      > Button {
        margin-top: 32px;
        margin-left: auto;
      }
    }
  }

  @media (max-width: ${BREAK_POINTS.medium}px) {
    > div {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 18px;
      justify-items: center;
      > div:first-of-type {
        max-width: 600px;
        width: 100%;
      }
    }
  }
`
