import React, { useEffect } from 'react'
import { useTransition } from 'react-spring'
import { useHistory } from 'react-router-dom'
import { AiOutlineInfoCircle, AiOutlineWarning, AiOutlineCheckCircle } from 'react-icons/ai'
import { ToastContainer, Message, Timer } from './styles'

type ToastProps = {
  action: React.Dispatch<ToastMessageProps>
  message: ToastMessageProps
}

export type ToastMessageProps = {
  value?: string
  type?: 'success' | 'error' | 'info'
  redirect?: string // URL redirection on the timer animation end (i.e: /silos)
  props?: any
}

/**
 * Toast
 * @param action - Set state function (hook)
 * @param message - Pass the message as an object, value for the actual message and type will define the background colour, types are success, error, info
 * @param redirect - String with the route name, this will run after the toast animation hides
 * @param props - Pass props to the redirected screen
 */

const Toast = ({ message: { value = '', type = 'success', redirect, props }, action }: ToastProps) => {
  const history = useHistory()
  const fade = useTransition(value, null, {
    from: { opacity: 0, transform: 'translate(100px, 0px)' },
    enter: { opacity: 1, transform: 'translate(-20px, 0px)' },
    leave: { opacity: 0, transform: 'translate(100px, 0px)' },
  })

  useEffect(() => {
    if (redirect) return history.push(redirect, { toast: { value, type, props } })
    const timeOut = setTimeout(() => {
      value && action({ value: '', type })
    }, 7000)
    return () => clearTimeout(timeOut)
    // eslint-disable-next-line
  }, [value])

  const SelectIcon = () => {
    switch (type) {
      case 'error':
        return <AiOutlineWarning size={25} color="white" />
      case 'info':
        return <AiOutlineInfoCircle size={25} color="white" />
      default:
        return <AiOutlineCheckCircle size={25} color="white" />
    }
  }

  return (
    <>
      {fade.map(
        ({ item, key, props: styles }) =>
          item && (
            <ToastContainer key={key} type={type} style={styles} onClick={() => action({ value: '', type })}>
              <SelectIcon />
              <Message>{value}</Message>
              <Timer />
            </ToastContainer>
          )
      )}
    </>
  )
}

export default Toast
