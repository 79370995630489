import React from 'react'
import { IssueHistoryContainer } from './styles'
import { BsArrowRight } from 'react-icons/bs'
import { IssuesHistoryParsed } from '../../../../services/types'

const IssueHistory = ({ history }: { history: IssuesHistoryParsed[] }) => {
  return (
    <IssueHistoryContainer>
      {history.map(({ updatedAt, progress, nextProgress, updatedBy, comments }) => (
        <div>
          <div>
            <div>
              <p>{progress}</p>
              {nextProgress && (
                <>
                  <BsArrowRight />
                  <p>{nextProgress}</p>
                </>
              )}
            </div>
            <p>{`${!nextProgress ? 'Created' : 'Updated'} by ${updatedBy} at ${updatedAt}`}</p>
          </div>

          {comments && (
            <div>
              <p>Comments:</p>
              <p>{comments}</p>
            </div>
          )}
        </div>
      ))}
    </IssueHistoryContainer>
  )
}

export default IssueHistory
