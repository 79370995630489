import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Label, Button, Select } from '@gearedapp/ga-storybook'
import Toast from '../../../../components/Toast'
import useCustomState from '../../../../services/hooks/useCustomState'
import useToast from '../../../../services/hooks/useToast'
import useAuth from '../../../../services/hooks/useAuth'

import { match } from 'react-router-dom'
import { Location } from 'history'
import {
  IssuesTypes,
  IssuesTypesWithId,
  IssuesHistory,
  IssuesHistoryParsed,
} from '../../../../services/types'

import Spinner from '../../../../components/Spinner'
import { ViewIssueContainer } from './styles'
import TextArea from '../../../../components/TextArea'
import { ISSUES_REF } from '../../../../services/api/firebase/firestore'
import { updateProgress } from '../../../../services/api/firebase/firestore/issues'
import BackTo from '../../../../components/BackTo'
import IssueHistory from '../IssueHistory'

const defaultState = {
  id: '',
  title: '',
  description: '',
  home: '',
  room: '',
  reportedAt: {} as any,
  reportedBy: '',
  progress: '',
  comments: '',
}

const options = [
  {
    value: 'To Do',
    label: 'To Do',
  },
  {
    value: 'In Progress',
    label: 'In Progress',
  },
  {
    value: 'Done',
    label: 'Done',
  },
]

type AddIssuesTypes = {
  match: match<{ id: string }>
  location: Location<IssuesTypesWithId>
}

const ViewIssue = ({ match, location: { state: data } }: AddIssuesTypes) => {
  const { user } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(!data && match.params.id)
  const { toast, setToast } = useToast()
  const { state, setState, setFullState } = useCustomState(defaultState)
  const [history, setHistory] = useState<IssuesHistoryParsed[]>([])
  const [option, setOption] = useState(options[0])
  const canEdit = user?.role === 'ADMIN' || user?.role === 'MANAGER' || user?.role === 'MAINTENANCE'

  const fetchData = async () => {
    try {
      const response = await ISSUES_REF.doc(match.params.id).get()
      if (response.exists) {
        const { reportedAt, comments, ...fetch } = response.data() as IssuesTypes

        let formattedReportAt = ''
        if (reportedAt) {
          formattedReportAt = moment.unix(reportedAt.seconds).format('DD/MM/YYYY')
        }

        setFullState({
          id: response.id,
          reportedAt: formattedReportAt,
          comments: '',
          ...fetch,
        })

        const findOption = options.find((x) => x.value === fetch.progress)

        if (findOption) {
          setOption(findOption)
        }
      }
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }
    setIsLoading(false)
  }

  const listenHistory = () => {
    ISSUES_REF.doc(match.params.id)
      .collection('history')
      .orderBy('updatedAt', 'desc')
      .onSnapshot(
        (snap) => {
          const historyArray = snap.docs.map((doc) => {
            const { updatedAt, ...other } = doc.data() as IssuesHistory
            let parseUpdatedAt = ''
            if (updatedAt) {
              parseUpdatedAt = moment.unix(updatedAt.seconds).format('DD/MM/YYYY HH:mm')
            }

            return {
              id: doc.id,
              updatedAt: parseUpdatedAt,
              ...other,
            }
          })

          setHistory(historyArray)
        },
        (err) => {
          setToast({ value: err.message, type: 'error' })
        }
      )
  }

  useEffect(() => {
    if (match.params.id) {
      fetchData()
    }

    listenHistory()

    return () => listenHistory()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setState(name, value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      if (canEdit) {
        setSubmitting(true)
        // Change description and progress
        await updateProgress({
          id: state.id,
          comments: state.comments,
          progress: option.value,
          updatedBy: user?.displayName || null,
        })
        setSubmitting(false)
        setToast({ value: 'Issue Updated' })
        setState('comments', '')
      }
    } catch (error) {
      console.log('handleSubmit ->  error.message', error.message)
      setToast({ value: error.message, type: 'error' })
      setSubmitting(false)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  const { title, description, reportedBy, reportedAt, home, room, comments } = state

  return (
    <>
      <Toast message={toast} action={setToast} />
      <ViewIssueContainer>
        <BackTo to="/issues" text="Return to Issues" />

        <h1>View Issue</h1>
        <div>
          <div>
            <div>
              <h2>{title}</h2>
              {canEdit ? (
                <Select
                  id="progress"
                  full
                  options={options}
                  setValue={setOption}
                  value={option}
                  disabled={submitting}
                />
              ) : (
                <Button disabled>{option.label}</Button>
              )}
            </div>
            <p>
              {home}, room: {room}
            </p>
            <p>
              Reported by {reportedBy}, on {reportedAt}
            </p>
            <div>
              <p>Description</p>
              <p>{description}</p>
            </div>
            <Label htmlFor="textarea" label="Comments" full>
              <TextArea
                id="textarea"
                name="comments"
                onChange={handleChange}
                value={comments}
                disabled={submitting || !canEdit}
              />
            </Label>
            {canEdit && (
              <Button disabled={submitting} onClick={handleSubmit}>
                {submitting ? 'Updating...' : 'Update Issue'}
              </Button>
            )}
          </div>
          <IssueHistory history={history} />
        </div>
      </ViewIssueContainer>
    </>
  )
}

export default ViewIssue
