import React from 'react'
import { NotFoundContainer, Overlay } from './styles'
import { Link } from 'react-router-dom'

const NotFound = () => (
  <Overlay>
    <NotFoundContainer>
      <h1>404</h1>
      <Link to="/">Click here to return to the Main Page</Link>
    </NotFoundContainer>
  </Overlay>
)

export default NotFound
