import styled from 'styled-components'

export const NavBarContainer = styled.nav`
  width: 100%;
  background: var(--white);
  display: flex;
  padding: 8px 32px;
  box-shadow: 1px 2px 9px 1px rgba(0, 0, 0, 0.05);
  vertical-align: center;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-wrap: wrap;
  > a {
    cursor: pointer;
    > img {
      height: 50px;
      object-fit: cover;
    }
  }

  > ul {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    margin: -16px;
    > li {
      margin: 16px;
      > a {
        color: var(--text);
        &:hover {
          color: var(--primary);
        }

        &.active {
          color: var(--primary);
        }
      }

      &:last-child {
        margin-left: 32px;
      }
    }
  }
`
