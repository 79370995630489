import styled from 'styled-components'
import PageContainer from '../../components/PageContainer'
import { BREAK_POINTS } from '../../services/styles/constants'

export const IssuesContainer = styled(PageContainer)`
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    > h1 {
      margin: 0;
    }
  }

  > div:nth-of-type(2) {
    margin-bottom: 18px;
    display: flex;
    justify-content: flex-end;
    > div:first-of-type {
      max-width: 180px;
      margin-right: 16px;
    }
    > div:last-of-type {
      max-width: 130px;
    }
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    > div:first-of-type > a {
      margin-top: 16px;
      margin-left: 0;
    }
    > div:nth-of-type(2) {
      max-width: 100%;
      margin: 0;
      margin-bottom: 16px;
    }
  }
`
