import firebase from 'firebase'
import { fireCallFN } from './firebaseFunctions'

export const loginWithUsernameAndPassword = async (username: string, password: string) => {
  const result = await fireCallFN('login', { username, password })

  if (result.data) {
    await firebase.auth().signInWithCustomToken(result.data)
  }
}
export const createAccount = async (username: string, password: string, role: string) => {
  await fireCallFN('createUser', { username, password, role })
}

export const deleteAccount = async (ids: string[]) => {
  await fireCallFN('removeUser', { ids })
}

const firebaseSignOut = () => firebase.auth().signOut()

const firebaseUser = () => firebase.auth().currentUser

const getCustomClaims = async () => (await firebase.auth().currentUser?.getIdTokenResult())?.claims

export { firebaseUser, firebaseSignOut, getCustomClaims }
