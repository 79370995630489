import React, { useState, useEffect } from 'react'
import { Button, Input, Label, Select } from '@gearedapp/ga-storybook'
import Toast from '../../../../components/Toast'
import useCustomState from '../../../../services/hooks/useCustomState'
import useToast from '../../../../services/hooks/useToast'
import useAuth from '../../../../services/hooks/useAuth'

import { match } from 'react-router-dom'
import { Location } from 'history'
import { IssuesTypes, IssuesTypesWithId } from '../../../../services/types'

import Spinner from '../../../../components/Spinner'

import { createIssue, updateIssue } from '../../../../services/api/firebase/firestore/issues'
import { AddIssueContainer } from './styles'
import TextArea from '../../../../components/TextArea'
import { ISSUES_REF, FIREBASE_TIMESTAMP } from '../../../../services/api/firebase/firestore'
import { OptionsType } from '@gearedapp/ga-storybook/dist/components/Select/types'
import BackTo from '../../../../components/BackTo'

const defaultState = {
  id: '',
  title: '',
  description: '',
  home: '',
  room: '',
  reportedAt: FIREBASE_TIMESTAMP,
  reportedBy: '',
  progress: '',
}

const homeOptions = [
  {
    value: 'Thorburn Manor',
    label: 'Thorburn Manor',
  },
  {
    value: 'Morningside Manor',
    label: 'Morningside Manor',
  },
  {
    value: 'Lorimer House',
    label: 'Lorimer House',
  },
  {
    value: 'Canal View Care',
    label: 'Canal View Care',
  },
  {
    value: 'Hapland Bow',
    label: 'Hapland Bow'
  },
  {
    value: 'Balcarres Court',
    label: 'Balcarres Court'
  },
  {
    value: '33/5 North Meggetland',
    label: '33/5 North Meggetland'
  },
  {
    value: 'The Gallolee',
    label: 'The Gallolee'
  },
  {
    value: '46/6 North Meggetland',
    label: '46/6 North Meggetland'
  },
]

type AddIssuesTypes = {
  match: match<{ id: string }>
  location: Location<IssuesTypesWithId>
}

const AddIssues = ({ match, location: { state: data } }: AddIssuesTypes) => {
  const { user, loading } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(!data && match.params.id)
  const { toast, setToast } = useToast()
  const { state, setState, setFullState } = useCustomState(defaultState)
  const { state: errorMessages, setState: setErrorMessage, resetState: resetErrors } = useCustomState(
    defaultState
  )

  const fetchData = async () => {
    try {
      const response = await ISSUES_REF.doc(match.params.id).get()
      if (response.exists) {
        const data = response.data() as IssuesTypes

        setFullState({
          id: response.id,
          ...data,
        })
      }
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (data) {
      setFullState(data)
    }
    if (!data && match.params.id) {
      fetchData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateForm = () => {
    resetErrors()

    const { title, description, home, room } = state

    let errors = 0

    if (!title) {
      setErrorMessage('title', 'Please add a title.')
      errors += 1
    }

    if (!description) {
      setErrorMessage('description', 'Please add a description.')
      errors += 1
    }

    if (!room) {
      setErrorMessage('room', 'Please add a room.')
      errors += 1
    }

    if (!home) {
      setErrorMessage('home', 'Please add a home.')
      errors += 1
    }

    return errors
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setState(name, value)
  }

  const handleSelect = (option: OptionsType) => {
    setState('home', option.value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setSubmitting(true)

      const errors = validateForm()

      if (!errors) {
        const { title, description, home, room } = state

        if (match.params.id) {
          // Update
          await updateIssue(state)
          setToast({ value: 'Issue updated successfully', redirect: '/issues' })
        } else {
          // Create
          await createIssue({
            title,
            description,
            home,
            room,
            reportedBy: user?.displayName,
            updatedBy: null,
          })
          setToast({ value: 'Issue created successfully', redirect: '/issues' })
        }
      }
      setSubmitting(false)
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
      setSubmitting(false)
    }
  }

  if (isLoading || loading) {
    return <Spinner />
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <AddIssueContainer>
        <BackTo to="/issues" text="Return to Issues" />

        <h1>{data ? 'Update Issue' : 'Create Issue'}</h1>

        <form onSubmit={handleSubmit}>
          <Input
            label="Title"
            type="text"
            name="title"
            onChange={handleChange}
            value={state.title}
            error={errorMessages.title}
            disabled={submitting}
            full
          />

          <div>
            <Select
              label="Home"
              id="home filter"
              options={homeOptions}
              setValue={handleSelect}
              value={homeOptions.find((x) => x.value === state.home)}
              disabled={submitting}
              full
            />

            <Input
              label="Room"
              type="text"
              name="room"
              onChange={handleChange}
              value={state.room}
              error={errorMessages.room}
              disabled={submitting}
              full
            />
          </div>

          <Label htmlFor="textarea" label="Description" error={errorMessages.description} full>
            <TextArea
              id="textarea"
              name="description"
              onChange={handleChange}
              value={state.description}
              disabled={submitting}
              error={errorMessages.description}
              full
            />
          </Label>

          <Button disabled={submitting} type="submit">
            {submitting ? 'Submitting...' : data ? 'Update Issue' : 'Submit Issue'}
          </Button>
        </form>
      </AddIssueContainer>
    </>
  )
}

export default AddIssues
