import React, { useState, useEffect } from 'react'
import { AddUsersContainer } from './styles'
import { Button, Input, Select } from '@gearedapp/ga-storybook'
import Toast from '../../../../components/Toast'
import useCustomState from '../../../../services/hooks/useCustomState'
import useToast from '../../../../services/hooks/useToast'
import useAuth from '../../../../services/hooks/useAuth'
import { OptionsType } from '@gearedapp/ga-storybook/dist/components/Select/types'
import { match } from 'react-router-dom'
import { Location } from 'history'
import { UsersTypes } from '../../../../services/types'
import { createAccount } from '../../../../services/api/firebase/firebaseAuth'
import Spinner from '../../../../components/Spinner'
import { fireCallFN } from '../../../../services/api/firebase/firebaseFunctions'
import { useNonInitialEffect } from '../../../../services/hooks/useNonInitialEffect'
import BackTo from '../../../../components/BackTo'

const defaultOptions = [
  {
    value: 'MAINTENANCE',
    label: 'Maintenance',
  },
  {
    value: 'NURSE',
    label: 'Nurse',
  },
  {
    value: 'CARE',
    label: 'Care',
  },
  {
    value: 'MANAGER',
    label: 'Manager',
  },
  {
    value: 'MANAGER',
    label: 'Manager',
  },
  {
    value: 'OFFICE_STAFF',
    label: 'Office Staff',
  },
]

const defaultState = {
  username: '',
  password: '',
  role: defaultOptions[1],
}

type AddUsersTypes = {
  match: match<{ id: string }>
  location: Location<UsersTypes>
}

const AddUsers = ({ match, location: { state: data } }: AddUsersTypes) => {
  const { user, loading } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(!data && match.params.id)
  const [options, setOptions] = useState<OptionsType[]>(defaultOptions)
  const { toast, setToast } = useToast()
  const { state, setState, setFullState } = useCustomState(defaultState)
  const { state: errorMessages, setState: setErrorMessage, resetState: resetErrors } = useCustomState(
    defaultState
  )

  const fetchUser = async () => {
    try {
      const res = await fireCallFN('getOneUser', { uid: match.params.id })
      if (res.data) {
        const { username, role } = res.data
        const findOption = defaultOptions.find((x) => x.value === role)
        setFullState({
          username,
          password: '',
          role: findOption || defaultOptions[1],
        })
      }
    } catch (error) {
      setToast({ type: 'error', value: error.message })
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (data) {
      const { username, role } = data
      setFullState({
        username,
        role: { value: role, label: role.toLowerCase().replace(/_/g, ' ') },
        password: '',
      })
    }
    if (!data && match.params.id) {
      fetchUser()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNonInitialEffect(() => {
    if (user?.role === 'ADMIN') {
      setOptions([
        ...defaultOptions,
        {
          value: 'ADMIN',
          label: 'Admin',
        },
      ])
    }
  }, [loading])

  const validateForm = () => {
    resetErrors()

    const { password, username } = state

    let errors = 0

    if (!match.params.id && !password) {
      setErrorMessage('password', 'Please add a password to continue.')
      errors += 1
    }

    if (!username) {
      setErrorMessage('username', 'Please add username to continue.')
      errors += 1
    }

    return errors
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setState(name, value)
  }

  const handleSelect = (value: OptionsType) => {
    setState('role', value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setSubmitting(true)

      const errors = validateForm()

      if (!errors) {
        if (match.params.id) {
          // Update
          const { username, password, role } = state
          await fireCallFN('updateUser', { id: match.params.id, username, password, role: role.value })
          setToast({ value: 'User updated successfully', redirect: '/users' })
        } else {
          // Create
          await createAccount(state.username, state.password, state.role.value)
          setToast({ value: 'User created successfully', redirect: '/users' })
        }
      }
      setSubmitting(false)
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
      setSubmitting(false)
    }
  }

  if (isLoading || loading) {
    return <Spinner />
  }

  return (
    <>
      <Toast message={toast} action={setToast} />
      <AddUsersContainer>
        <BackTo to="/users" text="Return to Users" />
        <h1>{data ? 'Update User' : 'Invite User'}</h1>

        <form onSubmit={handleSubmit}>
          <Input
            label="Username"
            type="text"
            name="username"
            onChange={handleChange}
            value={state.username}
            error={errorMessages.username}
            disabled={submitting}
            full
          />
          <Input
            label="Password"
            type="password"
            name="password"
            onChange={handleChange}
            value={state.password}
            error={errorMessages.password}
            disabled={submitting}
            full
          />
          <Select
            label="Role"
            id="roles"
            options={options}
            setValue={handleSelect}
            value={state.role}
            disabled={submitting}
            full
          />
          <Button disabled={submitting} type="submit">
            {submitting ? 'Submitting...' : data ? 'Update User' : 'Invite User'}
          </Button>
        </form>
      </AddUsersContainer>
    </>
  )
}

export default AddUsers
