import { useEffect, useState } from 'react'
import { ToastMessageProps } from '../../components/Toast'

import firebase from '../api/firebase/'
import { UserRoles } from '../types'

export interface User extends firebase.User {
  role?: UserRoles
}

/**
 * Custom hook which monitors the firebase authentication state
 */
const useAuth = () => {
  const [toast, setToast] = useState<ToastMessageProps>({ value: '' })
  const [state, setState] = useState<{ user: User | null; loading: boolean }>({ user: null, loading: true })

  useEffect(() => {
    // Listen for auth state changes
    const unSubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const idTokenResult = await user.getIdTokenResult()
          const role: UserRoles = idTokenResult.claims.role

          setState({ user: { ...user, role }, loading: false })
        } else {
          setState({ user: null, loading: false })
        }
      } catch (error) {
        setToast({ value: error.message, type: 'error' })
      }
    })

    // unSubscribe to the listener when unmounting
    return () => unSubscribe()
  }, [])

  return { ...state, toast, setToast }
}

export default useAuth
