import React from 'react'
import { BackToStyled } from './styles'
import { BsChevronLeft } from 'react-icons/bs'

type BackToProps = {
  to: string
  text: string
}

const BackTo = ({ to, text }: BackToProps) => (
  <BackToStyled to={to}>
    <BsChevronLeft size={12} /> {text}
  </BackToStyled>
)

export default BackTo
