import styled from 'styled-components'
import PageContainer from '../../../../components/PageContainer'
import { BREAK_POINTS } from '../../../../services/styles/constants'

export const AddUsersContainer = styled(PageContainer)`
  > form {
    background-color: var(--white);
    border-radius: 3px;
    padding: 16px;
    max-width: 500px;
    box-shadow: 1px 2px 9px 1px rgba(0, 0, 0, 0.05);

    > label {
      margin-bottom: 16px;
    }

    > button {
      margin-top: 32px;
      margin-left: auto;
    }
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    > div:first-of-type > a {
      margin-top: 16px;
    }
  }
`
