import React, { useState } from 'react'
import { Input, Button } from '@gearedapp/ga-storybook'
import { LoginContainer } from './styles'
import useCustomState from '../../services/hooks/useCustomState'

import Logo from '../../assets/logo.jpg'
import { loginWithUsernameAndPassword } from '../../services/api/firebase/firebaseAuth'
import Toast from '../../components/Toast'
import useToast from '../../services/hooks/useToast'
import Spinner from '../../components/Spinner'

const defaultState = {
  username: '',
  password: '',
}

const Login = () => {
  const { toast, setToast } = useToast()
  const [submitting, setSubmitting] = useState(false)
  const { state, setState } = useCustomState(defaultState)
  const { state: errorMessages, setState: setErrorMessage, resetState: resetErrors } = useCustomState(
    defaultState
  )

  const validateForm = () => {
    resetErrors()

    const { password, username } = state

    let errors = 0

    if (!password) {
      setErrorMessage('password', 'Please add a password to continue.')
      errors += 1
    }

    if (!username) {
      setErrorMessage('username', 'Please add username to continue.')
      errors += 1
    }

    return errors
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setState(name, value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      setSubmitting(true)

      const errors = validateForm()

      if (!errors) {
        await loginWithUsernameAndPassword(state.username, state.password)
      }
      setSubmitting(false)
    } catch (error) {
      setToast({ value: error.message, type: 'error' })
      setSubmitting(false)
    }
  }
  return (
    <>
      <Toast message={toast} action={setToast} />
      <LoginContainer>
        <div>
          {submitting ? (
            <Spinner message="Logging in, please wait." />
          ) : (
            <img alt="logo" src={Logo} loading="lazy" />
          )}
          <form onSubmit={handleSubmit}>
            <Input
              full
              label="Username"
              type="text"
              name="username"
              onChange={handleChange}
              error={errorMessages.username}
              disabled={submitting}
            />
            <Input
              full
              label="Password"
              type="password"
              name="password"
              onChange={handleChange}
              error={errorMessages.password}
              disabled={submitting}
            />
            <Button type="submit" full disabled={submitting}>
              Login
            </Button>
          </form>
        </div>
      </LoginContainer>
    </>
  )
}

export default Login
