import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { NavBarContainer } from './styles'
import Logo from '../../assets/logo.jpg'
import { Button } from '@gearedapp/ga-storybook'
import { firebaseSignOut } from '../../services/api/firebase/firebaseAuth'
import history from '../../services/config/router'
import { UserRoles } from '../../services/types'

const NavBar = ({ role }: { role?: UserRoles }) => {
  return (
    <NavBarContainer>
      <Link to="/">
        <img alt="logo" src={Logo} />
      </Link>
      <ul>
        <li>
          <NavLink exact to="/issues">
            View Issues
          </NavLink>
        </li>
        {role && (role === 'MANAGER' || role === 'ADMIN') && (
          <li>
            <NavLink to="/users">Users</NavLink>
          </li>
        )}
        {role && role !== 'MAINTENANCE' && (
          <li>
            <a href="https://app.lindemann.healthcare" target="_blank" rel="noopener noreferrer">
              Care Platform
            </a>
          </li>
        )}
        <li>
          <Button
            small
            onClick={async () => {
              await firebaseSignOut()
              history.push('/')
            }}
          >
            Logout
          </Button>
        </li>
      </ul>
    </NavBarContainer>
  )
}

export default NavBar
