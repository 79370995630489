import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const BackToStyled = styled(Link)`
  display: block;
  font-size: 1rem;
  color: var(--text);
  margin-bottom: 16px;
  transition: 0.2s color ease-in-out;
  &:hover {
    color: var(--primary);
  }
`
