import React from 'react'
import { WhisperSpinner } from 'react-spinners-kit'
import { SpinnerContainer } from './styles'

interface TwoColorSpinnerProps {
  message?: string
  size?: number
  frontColor?: string
  backColor?: string
  sizeUnit?: string
  loading?: boolean
}

const Spinner = ({ message = 'Loading', ...props }: TwoColorSpinnerProps) => (
  <SpinnerContainer>
    <WhisperSpinner frontColor="var(--primary)" backColor="var(--text)" size={80} {...props} />
    <h1>{message}</h1>
  </SpinnerContainer>
)

export default Spinner
