import styled from 'styled-components'

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;

  > div {
    max-width: 400px;
    width: 100%;
    background-color: var(--white);
    border-radius: 3px;
    padding: 32px;
    box-shadow: 1px 2px 9px 1px rgba(0, 0, 0, 0.05);
    > img {
      display: block;
      margin: 50px auto;
    }

    > form {
      > label {
        margin-bottom: 16px;
      }
      > button {
        margin-top: 32px;
      }
    }
  }
`
