import styled from 'styled-components'
import { BREAK_POINTS } from '../../../../services/styles/constants'

export const IssueHistoryContainer = styled.div`
  margin-left: 32px;
  > div {
    width: 100%;
    background-color: var(--white);
    border-radius: 3px;
    padding: 16px;
    box-shadow: 1px 2px 9px 1px rgba(0, 0, 0, 0.05);
    border: 0;
    margin-bottom: 8px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    > div:first-of-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      white-space: nowrap;
      > div {
        display: flex;
        align-items: center;
        > svg:first-of-type {
          margin-right: 16px;
        }
        > svg:last-of-type {
          margin: 0 8px;
        }
        > p {
          font-weight: normal;
          margin: 0;
          &:first-of-type {
            color: var(--text-disabled);
          }
          &:nth-of-type(2) {
            margin-right: 32px;
            color: var(--primary);
          }
        }
      }
      > p {
        font-size: 13px;
        font-weight: normal;
        margin: 0;
        &:first-of-type {
          color: var(--text-disabled);
        }
        &:nth-of-type(2) {
          margin-right: 32px;
          color: var(--primary);
        }
      }
    }

    > div:nth-child(2) {
      width: 100%;
      text-align: left;
      padding: 16px 0;
      border-top: 1px solid var(--border);
      margin-top: 16px;
      p:last-child {
        font-weight: normal;
        white-space: break-spaces;
      }
    }
  }

  @media (max-width: ${BREAK_POINTS.medium}px) {
    margin-left: 0;
    max-width: 600px;
  }
  @media (max-width: ${BREAK_POINTS.small}px) {
    > div {
      > div:first-of-type {
        flex-wrap: wrap;
        > div {
          margin-bottom: 8px;
        }
      }
    }
  }
`
