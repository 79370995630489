import { firestore } from 'firebase'

/* UTILS */

export const createDocument = (key: string) => firestore().doc(key)

/* REFERENCES */

export const ISSUES_REF = firestore().collection('issues')

/* STORAGE */

// example: export const DOCUMENTS_STORAGE_REF = storage().ref('documents')
export const FIREBASE_TIMESTAMP = firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp
