import { useState } from 'react'

/**
 * useCustomState
 * @param state - Return the state Object
 * @param setState - Set individual parts of the state (name, value)
 * @param resetState - Reset the state to the default value
 * @param setFullState - Replace the full object
 */

const useCustomState = <ObjectType,>(defaultState: ObjectType) => {
  const [state, changeState] = useState(defaultState)

  const setState = (name: string, value: any) => {
    changeState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setFullState = (data: ObjectType) => {
    changeState(data)
  }

  const resetState = () => changeState(defaultState)

  return { state, setState, resetState, setFullState }
}

export default useCustomState
