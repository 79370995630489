import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import NotFound from '../scenes/NotFound'

import useAuth from '../services/hooks/useAuth'
import Toast from '../components/Toast'
import history from '../services/config/router'
import Login from '../scenes/Login'
import Issues from '../scenes/Issues'
import Spinner from '../components/Spinner'
import Users from '../scenes/Users'
import AddUsers from '../scenes/Users/scenes/AddUsers'
import NavBar from '../components/NavBar'
import AddIssues from '../scenes/Issues/scenes/AddIssue'
import ViewIssue from '../scenes/Issues/scenes/ViewIssue'
import ProtectedRoute from './components/ProtectedRoute'

const Routes = () => {
  const { loading, user, toast, setToast } = useAuth()
  if (loading && !user?.role) return <Spinner />
  return (
    <Router history={history}>
      <Toast message={toast} action={setToast} />
      <Switch>
        <Route exact path="/" render={() => (user ? <Redirect to="/issues" /> : <Login />)} />
        {user && (
          <>
            <NavBar role={user?.role} />
            <Route path="/issues" component={Issues} exact />
            <Route path="/add-issue" component={AddIssues} />
            <Route path="/view-issue/:id" component={ViewIssue} />
            <Route path="/issues/:id" component={AddIssues} />
            <ProtectedRoute
              user={user}
              requiredRole={['ADMIN', 'MANAGER']}
              path="/users"
              component={Users}
              exact
            />
            <ProtectedRoute
              user={user}
              requiredRole={['ADMIN', 'MANAGER']}
              path="/add-user"
              component={AddUsers}
            />
            <ProtectedRoute
              user={user}
              requiredRole={['ADMIN', 'MANAGER']}
              path="/users/:id"
              component={AddUsers}
            />
          </>
        )}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
