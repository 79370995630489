import React, { useEffect, useState } from 'react'
import { Table, Modal } from '@gearedapp/ga-storybook'
import ButtonLink from '../../components/ButtonLink'
import { HistoryDataListProps, UsersTypes } from '../../services/types'
import Toast from '../../components/Toast'
import useToast from '../../services/hooks/useToast'
import { fireCallFN } from '../../services/api/firebase/firebaseFunctions'
import Spinner from '../../components/Spinner'
import history from '../../services/config/router'
import { deleteAccount } from '../../services/api/firebase/firebaseAuth'
import { UsersContainer } from './styles'

const Users = ({ location: { state }, history: { action } }: HistoryDataListProps) => {
  const [modalToggle, setModalToggle] = useState<string[]>([])
  const { toast, setToast } = useToast()
  const [data, setData] = useState<UsersTypes[]>([])
  const [loading, setLoading] = useState(true)

  const fetchUsers = async () => {
    try {
      const res = await fireCallFN('getAllUsers')
      if (res.data) {
        setData(res.data)
      }
    } catch (error) {
      setToast({ type: 'error', value: error.message })
    }

    setLoading(false)
  }

  useEffect(() => {
    if (state && state.toast && action === 'PUSH') setToast(state.toast)

    fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Modal
        toggle={modalToggle.length > 0}
        setToggle={(x) => {
          if (!x) {
            setModalToggle([])
          }
        }}
        title="Remove Users"
        actionTitle="Remove"
        action={async () => {
          if (modalToggle.length > 0) {
            setLoading(true)
            await deleteAccount(modalToggle)
            await fetchUsers()
            setModalToggle([])
            setLoading(false)
          }
        }}
      >
        <p>
          {modalToggle.length > 1
            ? `Are you sure you want to remove this ${modalToggle.length} users from the app?`
            : `Are you sure you want to remove ${
                data.find((x) => x.id === modalToggle[0])?.username
              } from the app?`}
        </p>
      </Modal>
      <Toast message={toast} action={setToast} />
      <UsersContainer>
        <div>
          <h1>Users</h1>

          <ButtonLink to="add-user">Add User</ButtonLink>
        </div>
        {data.length > 0 ? (
          <Table
            data={data}
            uid="id"
            fields={[
              {
                field: 'username',
                header: 'Username',
                enableSorting: true,
              },
              {
                field: 'role',
                header: 'Role',
                render: (value) => (
                  <p style={{ textTransform: 'capitalize', margin: 0 }}>
                    {value.toLowerCase().replace(/_/g, ' ')}
                  </p>
                ),
                enableSorting: true,
              },
              {
                field: 'createdAt',
                header: 'Created At',
                enableSorting: true,
              },
            ]}
            pagination
            pagesToDisplay={[
              {
                value: 5,
                label: '5',
              },
              {
                value: 10,
                label: '10',
              },
              {
                value: 30,
                label: '30',
              },
              {
                value: 50,
                label: '50',
              },
            ]}
            deleteRow={async (uid) => {
              setModalToggle(uid)
            }}
            editRow={(uid) => {
              history.push({
                pathname: `users/${uid}`,
                state: data.find((x) => x.id === uid),
              })
            }}
          />
        ) : (
          <h4>There are no users.</h4>
        )}
      </UsersContainer>
    </>
  )
}

export default Users
