import styled from 'styled-components'

export const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: black;
`

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: linear-gradient(354deg, var(--title) 0%, var(--primary) 100%);
  > h1 {
    font-size: 250px;
    color: rgba(0, 0, 0, 0.2);
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 1);
    margin: 0;
  }

  > a {
    color: var(--border);
    transition: color 0.3s ease-in-out;
    font-size: 1.666rem;
    &:hover {
      color: var(--white);
    }
  }
`
