import styled from 'styled-components'

export const SpinnerContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > h1 {
    margin: 0;
    margin-top: 30px;
  }
`
